import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { GET, POST } from '../../../../utils/webAPI.service';
import { loaderModal } from '../../../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Timer from '../../../../assets/images/timer.svg';
import DisableTimer from '../../../../assets/images/disable_timer.png';
import Cross from '../../../../assets/images/crossnew.png';
import CredoModal from '../../../../components/CredoModal';
import { LOSCreation } from './LenderModal/LosCreationModal';
import { ShareDocuments } from './LenderModal/Sharedocument';
import { CompleteOnboarding } from './LenderModal/CompleteonBoardingModal';
import { EMandate } from './LenderModal/Mandate';
import { ESign } from './LenderModal/Esign';
import { LoanstatusandRoi } from './LenderModal/LoanstatusandRoiModal';
import Button from '../../../../components/Button';
import {
  API_COMPLETE_ONBOARDING,
  API_LENDER_STAGE,
  API_LENDER_SUMMARY,
} from '../../../../utils/APIUrls';
import { rupeeDecimalFormatter, formatString } from '../../../../utils/utility';
import { LimitandUdyamUpdate } from './LenderModal/LimitAndUdyamModal';
import BankingModal from './LenderModal/BankingModal';

const getModalComponent = (stageName) => {
  switch (stageName) {
    case 'los_creation':
      return LOSCreation;
    case 'e-Signature':
      return ESign;
    case 'mandate':
      return EMandate;
    case 'documents_upload':
      return ShareDocuments;
    case 'loan_status':
      return LoanstatusandRoi;
    case 'complete_onboarding':
      return CompleteOnboarding;
    case 'banking':
      return BankingModal;
    default:
      return null; // Return null if no match
  }
};

class LenderService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      modalType: null,
      lenderStage: [],
      lenderSummary: {},
      errmessage: null,
    };
    this.errorRef = React.createRef();
  }
  toggleModal = (modalType) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state?.errmessage &&
      this.state?.errmessage !== prevState?.errmessage
    ) {
      this.errorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  completeOnboarding = () => {
    const { match } = this.props,
      { loanId } = match.params;
    POST(API_COMPLETE_ONBOARDING(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          alert('Onboarding completed successfully');
          this.lenderStage(loanId);
          this.toggleModal();
        } else if (data?.code === 999) {
          this.setState({
            errmessage: data?.message,
          });
          this.toggleModal();
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  lenderStage = (loanId) => {
    GET(API_LENDER_STAGE(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            lenderStage: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  };

  componentDidMount() {
    const { match } = this.props,
      { loanId } = match.params;
    this.lenderStage(loanId);
    GET(API_LENDER_SUMMARY(loanId))
      .then(({ data }) => {
        if (data?.code === 200) {
          this.setState({
            lenderSummary: data?.data,
          });
        } else {
          alert(data?.message);
        }
      })
      .catch((err) => {
        alert('Something went wrong');
        throw err;
      });
  }
  render() {
    const { match } = this.props,
      { loanId } = match.params;
    let { lenderStage, lenderSummary, errmessage } = this.state;

    return (
      <>
        <div className='lender_wrapper  container'>
          <Helmet>
            <meta charSet='utf-8' />
            <title> LenderService</title>
          </Helmet>
          <div className='row d-flex justify-content-end mt-5 mr-3'>
          <Button
              onClick={() => this.toggleModal(LoanstatusandRoi)}
              className='px-4 py-2 mr-4'
             
            >
             Loan Status & ROI
            </Button>
            <Button
              onClick={() => this.toggleModal(LimitandUdyamUpdate)}
              className='px-4 py-2'
            >
              Update Limit or Udyam
            </Button>
          </div>

          <div className='card'>
            <div className='invoice-header d-flex justify-content-between'>
              <h3 className='card_heading'> SUMMARY</h3>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='row'>
                  <div className='col-md-3 text-grey'>
                    Lender Name
                    <div className='text-bold'>
                      {lenderSummary?.lenderName || '-'}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Requested Limit Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(lenderSummary?.losAmount, '₹')}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Approved Limit Amount
                    <div className='text-bold'>
                      {rupeeDecimalFormatter(
                        lenderSummary?.approvedAmount,
                        '₹'
                      )}
                    </div>
                  </div>
                  <div className='col-md-3 text-grey'>
                    Loan Status
                    <div className='text-bold'>
                      {formatString(lenderSummary?.loanStatus) || '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {lenderStage?.length > 0 && (
            <h1 className='heading'>Application stages</h1>
          )}

          {/* card */}
          <div className='row'>
            {lenderStage?.map((ele) => {
              const ModalComponent = getModalComponent(ele.stageName);
              return (
                <div className='col-4' key={ele.stageName}>
                  <div className='status-card mt-5'>
                    <div className='status-header  mb-4'>
                      <h3 className='title'>
                        {ele?.applicationTrackerName?.charAt(0).toUpperCase() +
                          ele?.applicationTrackerName?.slice(1)}
                      </h3>
                    </div>
                    <div className='order-info'>
                      <p className='text-grey mb-0'>
                        Status:{' '}
                        <span
                          className={`capsule ${
                            ele?.status === 'pending'
                              ? 'capsule-blue'
                              : ele?.status === 'complete'
                              ? 'capsule-green'
                              : ''
                          } ml-2`}
                        >
                          {ele?.status?.charAt(0).toUpperCase() +
                            ele?.status?.slice(1) || '-'}
                        </span>
                      </p>
                    </div>
                    <div className='order-info'>
                      <p className='text-grey mb-0'>
                        Order Number:
                        <span className='text-black ml-3'>
                          {ele?.order || 'N/A'}
                        </span>
                      </p>
                    </div>
                    <div className='order-info'>
                      <p className='text-grey mb-0'>
                        Completed At:
                        <span className='text-black ml-3'>
                          {ele?.completedAt || 'N/A'}
                        </span>
                      </p>
                    </div>

                    {/* Conditional rendering of buttons based on status and modal availability */}
                    {ele?.status === 'pending' && ModalComponent ? (
                      <TriggerButton
                        onClick={() => this.toggleModal(ModalComponent)}
                        disabled={false}
                      />
                    ) : (
                      <DisableButton />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* errormessage from lender */}

          {errmessage && (
            <div className='row my-5' ref={this.errorRef}>
              <div className='col d-flex'>
                <ErrorCapsule message={errmessage} />
              </div>
            </div>
          )}
        </div>
        <CredoModal
          isOpen={this.state?.isModalOpen}
          styles={
            this.state?.modalType === ShareDocuments ||
            this.state?.modalType === BankingModal
              ? {
                  content: {
                    width: '80%',
                    height: '80%',
                  },
                }
              : {
                  content: {
                    width: '60rem',
                    height: '30rem',
                  },
                }
          }
          closeAction={() => this.toggleModal('')}
          completeOnboarding={() => this.completeOnboarding()}
          loanId={loanId}
          refresh={() => this.lenderStage(loanId)}
          RenderingComponent={this.state.modalType}
          documentList={this.state.documentList}
        />
      </>
    );
  }
}

const ErrorCapsule = ({ message = 'E-Mandate is Incomplete!' }) => {
  return (
    <div className='error-capsule ml-3'>
      <span className='error-capsule__message'>{message}</span>
      <img src={Cross} className='error-capsule__icon' />
    </div>
  );
};

const TriggerButton = ({ disabled = false, onClick }) => {
  return (
    <span onClick={onClick} className='button trigger_button  mt-3'>
      <img src={Timer} alt='Timer icon' />
      Trigger
    </span>
  );
};

const DisableButton = ({ disabled = false, onClick }) => {
  return (
    <span onClick={onClick} className='button disabled  mt-3'>
      <img src={DisableTimer} alt='Timer icon' />
      Trigger
    </span>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(LenderService);
